'use strict';

/**
 * @name reportsGenerationCtrl
 * @desc Controller for Reports generation
 */

function reportsGenerationCtrl($scope, reports, toastr, businessList, singleBU, industryList, singleIndustry, $timeout, $filter, profile,
  globalFunc, checkReportStatus, $interval, pathConstants, reportTypes, $state,
  searchModule, UserPermissions, reportsV2, reportsV2Config, $http, modeOfPurchaseList) {

  //region Variable and scope declaration
  var usersCompaniesCode = [],
    usersCompanies = profile.companies,
    usersPerms = profile.view_perms,
    usersBUCode = [];
  var selectedCountryCode = undefined;
  var allBUs = {
    descr: 'All Available Business Units',
    _id: 'all'
  };
  $scope.reports = {};
  $scope.business = businessList;
  // Used for sorting business units when displaying them in a list
  $scope.businessId = {};
  $scope.selectedCompanies = [];
  $scope.selectedCostCenters = [];
  $scope.selectedSuppliers = [];
  $scope.selectedBudgetType = {};
  $scope.selectedBudgetType.list = null;
  $scope.costCenterList = [];
  $scope.companyList = [];
  $scope.company_ids = [];
  $scope.companyCodes = [];
  $scope.supplierCodes = [];
  $scope.searchedSuppliers = [];
  $scope.searchedBillingCompanies = [];
  $scope.shadowCompanySearchField = {
    text: ''
  };
  $scope.date_type = [{
      id: 'receivedDate',
      name: 'Received'
    },
    {
      id: 'GRNDate',
      name: 'GRN'
    }
  ];
  $scope.spend_date_type = [{
      id: 'PODate',
      name: 'PO Date'
    },
    {
      id: 'PRDate',
      name: 'PR Date'
    }
  ];
  $scope.budgetType = [{
      id: 'master',
      descr: 'Master'
    },
    {
      id: 'assignment',
      descr: 'Assignment'
    },
    {
      id: 'itemBreakdown',
      descr: 'Item Breakdown'
    }
  ];
  $scope.minionConfig = {};
  $scope.reportingCurrencyList = [{
      id: 'companyCurrency',
      descr: 'Company Currency'
    },
    {
      id: 'reportingCurrency',
      descr: 'Reporting Currency'
    }
  ];
  $scope.filterCompanyBy = [{
      id: 'billingCompany',
      descr: 'Billing Company',
      value: 'bill_company'
    },
    {
      id: 'industryBusinessUnit',
      descr: 'Industry & Business Unit',
      value: 'IBU'
    }
  ];
  $scope.searchMode = null;

  $scope.modeOfDisposalsList = [{
      value: 'Written Off',
      descr: 'Written Off'
    },
    {
      value: 'Disposal With Proceed',
      descr: 'Disposal With Proceed'
    },
    {
      value: 'Transfer',
      descr: 'Transfer'
    }
  ];
  $scope.prStatusSelection = [{
      key: 'all',
      value: 'All',
    },
    {
      key: 'pending',
      value: 'Pending Approval',
    },
    {
      key: 'approved',
      value: 'Approved',
    }
  ];

  $scope.poStatusSelection = [
    {
      key: 'all',
      value: 'All',
    },
    {
      key: 'pending',
      value: 'Pending Approval',
    },
    {
      key: 'approved',
      value: 'Approved',
    },
    {
      key: 'withdrawn',
      value: 'Withdrawn',
    },
    {
      key: 'rejected',
      value: 'Rejected',
    },
    {
      key: 'deleted',
      value: 'Deleted',
    },
  ];
  $scope.modeOfPurchase = {
    selected: null
  };
  $scope.modeOfPurchaseList = modeOfPurchaseList;
  $scope.onSelectModePurchase = onSelectModePurchase;
  $scope.onSelectedCompanyCountry = onSelectedCompanyCountry;
  $scope.countries = [];
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.removeCompany = removeCompany;
  $scope.addToSelectedCompany = addToSelectedCompany;
  $scope.prStatus = {
    selected: $scope.prStatusSelection[0]
  };
  $scope.poStatus = {
    selected: $scope.poStatusSelection[0]
  };
  $scope.searchCompanies = {};
  $scope.searchCompanies = searchCompanies;
  $scope.industryList = industryList;
  $scope.industryId = {};
  $scope.onSelectIndustry = onSelectIndustry;
  $scope.sortedIndustry = sortedIndustry;
  $scope.onSelectedFilterCompanyBy = onSelectedFilterCompanyBy;
  $scope.reportingCurrency = {
    selected: null
  };
  $scope.modeOfDisposal = {
    selected: null
  };

  $scope.getCompanies = getCompanies;
  $scope.generateReports = generateReports;
  $scope.hasSupplierTagPermission = hasSupplierTagPermission;
  $scope.generateOptionsBasedOnReportType = generateOptionsBasedOnReportType;
  $scope.onSelectBusinessUnit = onSelectBusinessUnit;
  $scope.openDatepicker = openDatepicker;
  $scope.onSelectDateType = onSelectDateType;
  $scope.sortedBusiness = sortedBusiness;
  $scope.getSelectedCompanies = getSelectedCompanies;
  $scope.onSelectedCompanyChanges = onSelectedCompanyChanges;
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;
  $scope.onSupplierSelect = onSupplierSelect;
  $scope.getSuppliers = getSuppliers;

  $scope.reportTypes = reportTypes.getReportTypes();

  $scope.datepickerOpened = {
    startDate: false,
    endDate: false
  };

  //endregion

  // Return string of selected company
  function getSelectedCompanies(array, key) {
    return globalFunc.createStringBasedOnObjectKeyInArray(array, key);
  }


  // Function for sorting BU on list
  function sortedBusiness(business) {
    if (business.descr === 'All Available Business Units') {
      return -1;
    }
    return business.descr;
  }

  function sortedIndustry(industry) {
    if (industry.descr === 'All Available Industries') {
      return -1;
    }
    return industry.descr;
  }


  //region On select functions

  // Set selection based on field type
  function onSelectSingle(fieldType, selection) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selection;
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selection;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selection;
        break;
    }
  }

  // Set selection to all based on field type
  function onSelectAll(fieldType, selectionList) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selectionList;
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selectionList;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selectionList;
        break;
    }
  }

  // Set selection to none based on field type
  function onSelectNone(fieldType) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = [];
        break;
      case 'costCenter':
        $scope.selectedCostCenters = [];
        break;
      case 'supplier':
        $scope.selectedSuppliers = [];
        break;
    }
  }

  function generateOptionsBasedOnReportType(reportType) {
    $scope.selectedCompanies = [];
    $scope.selectedSuppliers = [];
    switch (reportType.code) {
      case 'SPEND':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = true;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.poStatusSelect = false;
        $scope.prStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'RECEIVING':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = true;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.poStatusSelect = false;
        $scope.prStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION_COUNTER':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'PR_PO_COUNT_PER_COMPANY':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION_DETAILS':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'SUPPLIER_TAGGING':
        $scope.buSelect = true;
        $scope.supplierSelect = true;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'BUDGET':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_DISPOSAL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = true;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = true;
        break;
      case 'EXCEL_PO_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = true;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_PURCHASE_STATUS_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = true;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = true;
        break;
      default:
        $scope.buSelect = false;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
    }
  }

  function onSelectBusinessUnit(businessUnit) {
    $scope.selectedCompanies = [];
    $scope.businessUnitSelected = true;
    $scope.inputId = businessUnit._id;

    getCompanies($scope.inputId);
  }

  function onSelectDateType(type) {
    $scope.input_date_type = type;
  }
  //endregion

  function openDatepicker($event, which) {
    if ($scope.datepickerOpened[which]) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      $timeout(function () {
        $scope.datepickerOpened[which] = true;
      })
    }

    if (!!$scope.endDate && !!$scope.endDate.date) {
      $scope.startDateOptions = {
        formatYear: 'yy',
        maxDate: $scope.endDate.date
      };
    }

    //adding 1 year range for max date in endDateOptions
    var oneYearRange = new Date($scope.startingDate.date);
    oneYearRange.setFullYear(oneYearRange.getFullYear() + 1);
    if (!!$scope.startingDate && !!$scope.startingDate.date) {
      $scope.endDateOptions = {
        formatYear: 'yy',
        minDate: $scope.startingDate.date,
        maxDate: oneYearRange,
      };
    }

    //reset the end date to match starting date
    if ($scope.startingDate.date > $scope.endDate.date){
      $scope.endDate.date = $scope.startingDate.date;
    }

    if (!!$scope.reports.type && !!$scope.reports.type.code && $scope.reports.type.code !== 'BUDGET') {
      $scope.startDateOptions.maxDate = new Date();
      if ($scope.endDateOptions.maxDate > new Date()){
        $scope.endDateOptions.maxDate = new Date();
      }
    }
  }

  //watch if there are  changes in starting date
  $scope.$watch('startingDate.date', function(newDate, oldDate) {
    var endDate = $scope.endDate.date;
    // Selected end date is more than 1 year away from the start date
    if (newDate !== oldDate && (endDate - newDate > 365 * 24 * 60 * 60 * 1000) ) {
        var oneYearRange = new Date(newDate);
        $scope.endDate.date = oneYearRange.setFullYear(oneYearRange.getFullYear() + 1);
      }
    if (newDate > $scope.endDate.date){
      $scope.endDate.date = $scope.startingDate.date;
    }
  });

  //region Functions for generating array for payload
  function extractCompanyId() {
    $scope.company_ids = [];
    _.forEach($scope.selectedCompanies, function (company) {
      if (!!company && !!company._id)
        $scope.company_ids.push(company._id);
    });
  }

  function extractCompanyCode() {
    $scope.companyCodes = [];
    _.forEach($scope.selectedCompanies, function (company) {
      if (!!company && !!company.code)
        $scope.companyCodes.push(company.code);
    });
  }

  function extractSupplierCode() {
    $scope.supplierCodes = [];
    _.forEach($scope.selectedSuppliers, function (supplier) {
      if (!!supplier && !!supplier.basic_info && !!supplier.basic_info.code)
        $scope.supplierCodes.push(supplier.basic_info.code);
    });
  }

  /**
   * Extract cost center code
   */
  function extractCostCenterCode() {
    $scope.costCenterCodes = [];
    _.forEach($scope.selectedCostCenters, function (costCenter) {
      if (!!costCenter && !!costCenter.code)
        $scope.costCenterCodes.push(costCenter.code);
    });
  }
  //endregion

  //region Functions for getting companies and supplier listing
  function getCompanies(businessUnit) {
    var param = {};
    var resource;
    if (businessUnit === 'all') {
      resource = searchModule;
      param.offset = 0;
      param.module = 'companies';
      param.order = 1;
      param.order_by = 'descr';
    } else {
      resource = singleBU;
      param.id = businessUnit;
    }


    $scope.loading = true;

    resource.get(param,
      function (success) {
        if (!!success.content.data.companies) {
          $scope.companyList = success.content.data.companies;
        } else {
          $scope.companyList = success.content.data;
        }

        if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
          !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 1)
        ) {
          var ReportManagerCompanies = globalFunc.getUserCompanyListForRole(profile, 'Report Manager');
          var ReportManagerCompanyCodes = _.pluck(ReportManagerCompanies, 'code');

          $scope.companyList = filterUsersData($scope.companyList, 'code', ReportManagerCompanyCodes, true);
        }

        $scope.loading = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }


  function getSuppliers(val) {
    if (!!val && val.length > 2) {

      var params = {
        module: 'suppliers',
        'criteria[0][basic_info|descr]': val,
        criteria_operator: 'and',
        offset: 10
      };

      searchModule.get(
        params,
        function (resource) {
          if (resource.content.data.length === 0) {
            $scope.searchedSuppliers = [];
          } else {

            $scope.searchedSuppliers = resource.content.data;
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function onSupplierSelect(supplier, action) {
    if (action === 'assign') {
      $scope.selectedSuppliers.push(supplier);
    } else {
      $scope.selectedSuppliers = globalFunc.removeValueFromArray($scope.selectedCompanyArray, '_id', supplier._id);
    }
  }


  //region Functions for checking permission and validation
  function hasSupplierTagPermission() {
    return (!!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') &&
      !!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update'));
  }

  function checkForGlobalReportRole() {
    var role = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager');
    if (!role)
      return false;

    if (!!role.company_code && role.company_code.length > 0)
      return false;

    return true;
  }

  /**
   * validation for report generation form
   * @returns {boolean}
   */
  function validate() {
    extractCompanyId();
    extractCompanyCode();
    extractSupplierCode();
    extractCostCenterCode();
    if (!$scope.reports.type) {
      return false;
    }

    /**
     * Only validate for spend report or receiving details report type
     */
    if ($scope.reports.type.code === 'SPEND' || $scope.reports.type.code === 'RECEIVING' ||
      $scope.reports.type.code === 'BUDGET') {
      if ($scope.company_ids.length === 0) {
        return false;
      }

      if (!$scope.startingDate || !$scope.startingDate.date || !$scope.endDate || !$scope.endDate.date) {
        return false;
      }

      if ($scope.startingDate > $scope.endDate) {
        toastr.error('Ending date must be older than starting date');
        return false;
      }
      if (($scope.reports.type.code === 'SPEND' || $scope.reports.type.code === 'RECEIVING') &&
        (!$scope.input_date_type || !$scope.input_date_type.id)) {
        return false;
      }
    }
    if (($scope.reports.type.code === 'REQUISITION' || $scope.reports.type.code === 'BUDGET' ||
        $scope.reports.type.code === 'REQUISITION_DETAILS') &&
      (!$scope.company_ids || $scope.company_ids.length === 0)) {
      return false;
    }
    if ($scope.reports.type.code === 'SUPPLIER_TAGGING') {
      if ($scope.company_ids.length === 0 && $scope.selectedSuppliers.length === 0) {
        return false;
      } else if ($scope.company_ids.length === 0 && $scope.selectedSuppliers.length > 0) {
        return true;
      } else if ($scope.company_ids.length > 0 && $scope.selectedSuppliers.length === 0) {
        return true;
      }
    }

    if ($scope.reports.type.code === 'BUDGET') {
      if (!$scope.selectedBudgetType.list || !$scope.selectedBudgetType.list.id) {
        return false;
      }
    }

    return true;
  }
  //endregion

  //region Functions for generating report and check
  function downloadReport(hash) {
    var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
      id: hash
    });
    window.open(newUrl, '_self');
  }

  /**
   * Function to check the export status with intervals
   * @param id
   */
  function checkReport(id) {

    checkReportStatus.get({
        id: id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status) {

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for report generation
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch (resource.content.data.status) {
            case 0:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              break;
            case 1:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              break;
            case 2:
              if (!!resource.content.data.hash) {
                toastr.success(resource.content.message, {
                  timeOut: 1500,
                  extendedTimeOut: 0,
                  onClose: downloadReport(resource.content.data.hash)
                });
              } else {
                toastr.error(resource.content.message, {
                  timeOut: 0,
                  extendedTimeOut: 0
                });
              }
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              toastr.error(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
          }
          $scope.checkingReportStatus = checkingStatus;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function resetAfterReportGenSubmit() {
    $scope.input_date_type = '';
    $scope.supplierSelect = '';
    $scope.selectedSuppliers = '';
    $scope.buSelect = '';
    $scope.reports.type = '';
    $scope.reports.businessId = '';
    $scope.reports.industryId = '';
    $scope.selectedCompanies = '';
    $scope.spendReportBasedOn = false;
    $scope.dateTypeSelect = false;
    $scope.dateSelect = false;
    $scope.startingDate = {
      date: new Date()
    };
    $scope.endDate = {
      date: new Date()
    };
    $scope.selectedCostCenters = [];
    $scope.selectedBudgetType.list = null;
    $scope.companyList = [];
    $scope.reportingCurrencySelect = false;
    $scope.reportingCurrency = {
      selected: null
    };
    $scope.modeOfDisposalSelect = false;
    $scope.modeOfDisposal = {
      selected: null
    };
    $scope.prStatusSelect = false;
    $scope.prStatus = {
      selected: $scope.prStatusSelection[0]
    };
    $scope.poStatusSelect = false;
    $scope.poStatus = {
      selected: $scope.poStatusSelection[0]
    };

    $scope.modeOfPurchaseSelect = false;
    $scope.modeOfPurchase = {
      selected: null
    };

    $scope.countrySelect = false;
  }

  /**
   * Report Generation
   */
  function generateReports(startDate, endDate) {
    $scope.checkingReportStatus = true;
    startDate = globalFunc.convertDateToTimestamp(startDate);
    endDate = globalFunc.convertDateToTimestamp(endDate);

    if (!validate()) {
      toastr.error('Please fill all fields before proceeding');
      $scope.checkingReportStatus = false;
    } else {
      var inputDateType = '';
      if ($scope.reports.type.code === 'RECEIVING' || $scope.reports.type.code === 'SPEND') {
        inputDateType = $scope.input_date_type.id;
      }

      var submitData = {
        reportType: $scope.reports.type.code,
        dateFrom: startDate,
        dateTo: endDate,
        companyCodes: $scope.companyCodes,
        supplierCodes: $scope.supplierCodes,
        companyIDs: $scope.company_ids,
        costCenterCodes: !!$scope.costCenterCodes ? $scope.costCenterCodes : [],
        budgetType: !!$scope.selectedBudgetType.list && !!$scope.selectedBudgetType.list.id ?
          $scope.selectedBudgetType.list.id : null,
        dateType: inputDateType,
        reportingCurrency: $scope.reportingCurrencySelect ? $scope.reportingCurrency.selected.id : '',
        modeOfDisposal: $scope.modeOfDisposalSelect ? $scope.modeOfDisposal.selected.value : '',
        prStatus: $scope.prStatusSelect ? $scope.prStatus.selected.key : '',
        poStatus: $scope.poStatusSelect ? $scope.poStatus.selected.key : '',
        modeOfPurchase: $scope.modeOfPurchaseSelect ? $scope.modeOfPurchase.selected.code : ''
      };

      var activeReportService = reports;

      if (!!$scope.minionConfig && !!$scope.minionConfig.enabled && !!$scope.minionConfig.enabled_reports) {
        if (!!$scope.minionConfig.enabled_reports[$scope.reports.type.code.toLowerCase()]) {
          activeReportService = reportsV2;
        }
      }

      activeReportService.post({},
        submitData,
        function (resource) {
          toastr.info('Report is being generated. The download will start automatically', {
            timeOut: 0,
            extendedTimeOut: 0
          });
          $scope.startChecking = $interval(function () {
            checkReport(resource.content.data[0]);
          }, 5000);
          resetAfterReportGenSubmit();
        },
        function (error) {
          $scope.checkingReportStatus = false;
          globalFunc.objectErrorMessage(error);
        }
      )
    }
  }
  //endregion

  //region Functions for filtering data
  /**
   * filter data based on the params
   * @param array
   * @param filteredKey
   * @param filterData
   * @param reject
   * true or false to reject data
   * @returns {*}
   */
  function filterUsersData(array, filteredKey, filterData, reject) {
    if (filterData.length === 0) return array;

    return _.reject(array, function (val) {
      if (!!reject)
        return filterData.indexOf(val[filteredKey]) === -1;
      else
        return filterData.indexOf(val[filteredKey]) > -1;
    });
  }

  function dataFilter() {
    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager') &&
      !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 1)
    ) {
      var companyList = profile.companies;
      usersCompaniesCode = globalFunc.arrayOfObjectToString(companyList, 'code');
      var usersBUList = globalFunc.arrayOfObjectToString(companyList, 'business_unit');
      usersBUList = globalFunc.removeDuplicateObjects(usersBUList, 'code');
      usersBUCode = globalFunc.arrayOfObjectToString(usersBUList, 'code');
    }
  }
  //endregion

  /**
   * Get cost center list when any selected company changes
   */
  function onSelectedCompanyChanges() {
    $scope.costCenterList = [];

    //stop when no company is selected or is not in budget report
    if (!$scope.selectedCompanies.length || $scope.reports.type.code !== 'BUDGET') {
      return;
    }

    extractCompanyCode();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'or',
      order_by: 'descr',
      order: 1
    };

    _.forEach($scope.companyCodes, function (companyCode, index) {
      params['criteria[company_code][' + index + ']'] = companyCode;
    });

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterList = resource.content.data;
          _.forEach($scope.costCenterList, function (costcenter) {
            costcenter.descr = costcenter.descr + ' [' + costcenter.company_name + ']';
          });
        }
      }
    )
  }

  function initialize() {
    $scope.$watch('selectedCompanies.length', function () {
      onSelectedCompanyChanges();
    });

    if (!!$scope.$parent)
      $scope.$parent.activatedTab = $scope.$parent.tabData[1];
    else
      $scope.activatedTab = $scope.tabData[1];

    $scope.startingDate = {
      date: new Date()
    };
    $scope.endDate = {
      date: new Date()
    };

    $scope.startDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };
    $scope.endDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };
    $scope.countries = [
      {
        'code': undefined,
        'descr': 'All'
      },
      {
      'code': 'MY',
      'descr': 'Malaysia'
      },
      {
      'code': 'IND',
      'descr': 'Indonesia'
      }
    ];

    dataFilter();
    if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') &&
      !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 1)
    ) {
      // According to KyeYin, normal report manager dont need select by business unit
      // https://github.com/Metabuyer/synergy/issues/9041
      // If needed, just enable next line
      // $scope.business = filterUsersData($scope.business, 'code', usersBUCode, true);
      $scope.business = []; // remove this if need filter by business unit
    }

    $scope.business.unshift(allBUs);

    $scope.multiselectTranslation = {
      selectAll: 'Select all',
      selectNone: 'Select none',
      reset: 'Reset',
      search: 'Type here to search...',
      nothingSelected: 'Select'
    };

    reportsV2Config.get({}, function (res) {
      $scope.minionConfig = res.content.data.config
    })

  }

  function onSelectIndustry(industry) {}

  function searchCompanies(val) {
    if (!!val && val.length > 2) {
      var moduleParam = {
        module: 'companies'
      };

      var additionalParams = {
        params: {
          'criteria[0][descr]': val,
          'criteria[0][code]': val,
          'criteria[0][country]': selectedCountryCode,
          criteria_operator: 'or',
          'offset': 10
        }
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams)
        .then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          }

          return response.data.content.data.map(function (company) {
            return company;
          });
        });
    }
  }

  function onSelectedFilterCompanyBy(selected) {
    $scope.searchMode = selected.value;
    //clear
  }

  function addToSelectedCompany(selected) {
    if (!globalFunc.findInArray($scope.searchedBillingCompanies, '_id', selected._id)) {
      $scope.searchedBillingCompanies.push(selected);
      $scope.selectedCompanies.push(selected);
    }
    $scope.shadowCompanySearchField = {
      text: ''
    };
  }

  function removeSelectedCompany() {
    searchBillingCompany.selected = false;

  }

  function removeCompany(index) {
    $scope.searchedBillingCompanies.splice(index, 1);
  }

  function onSelectedCompanyCountry(cc) {
    selectedCountryCode = cc.descr; //DB uses the descr
  }

  function onSelectedCountry(country) {
    $scope.corresponding_address.state = undefined;
    $scope.corresponding_address.country = country.descr;
    if (country.descr === "Malaysia") {
      $scope.selectedMalaysia = true;
    } else {
      $scope.selectedMalaysia = false;
    }
  }

  function onSelectModePurchase(mode) {}
  initialize();
}

reportsGenerationCtrl.$inject = [
  '$scope', 'reports', 'toastr', 'businessList', 'singleBU', 'industryList', 'singleIndustry', '$timeout', '$filter', 'profile',
  'globalFunc', 'checkReportStatus', '$interval', 'pathConstants', 'reportTypes', '$state', 'searchModule',
  'UserPermissions', 'reportsV2', 'reportsV2Config', '$http', 'modeOfPurchaseList'
];

angular
  .module('metabuyer')
  .controller('reportsGenerationCtrl', reportsGenerationCtrl);
