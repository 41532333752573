'use strict';

function OrdersCtrl($scope, $state, $rootScope, Orders, isDeveloper, starringPO, toastr, globalFunc, getManyPOPDFHash, $filter, pathConstants) {

  $scope.isDeveloper = isDeveloper;
  $scope.starred = starred;
  $scope.addToSelectedPO = addToSelectedPO;
  $scope.poIsSelected = poIsSelected;
  $scope.clearSelectedPO = clearSelectedPO;
  $scope.removeFromSelectedPO = removeFromSelectedPO;
  $scope.exportSelectedPoAsPdf = exportSelectedPoAsPdf;
  $scope.selectedPO = [];
  $scope.hiddenColumn = {id: null, conditional: true};
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  var tempState = _.cloneDeep($state.params);
  var developerTabs = [
    {
      heading: 'Pending',
      route: 'main.user.orders.manage',
      key: 'pending',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'PENDING',
        cursor: null
      })
    },
    {
      heading: 'Approved',
      route: 'main.user.orders.manage',
      key: 'approved',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'APPROVED',
        cursor: null
      })
    }
  ];

  var developerMoreTabs = [
    {
      heading: 'Rejected',
      route: 'main.user.orders.manage',
      key: 'rejected',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'REJECTED',
        cursor: null
      })
    },
    {
      heading: 'Withdrawn',
      route: 'main.user.orders.manage',
      key: 'withdrawn',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'WITHDRAWN',
        cursor: null
      })
    }
  ];

  if (!$rootScope.isMobileMode) {
    if($rootScope.isV2Enabled) {
      $scope.tabData = [
        {
          heading: 'All',
          route: 'main.user.orders.manage',
          key: 'all',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'ALL',
            cursor: null
          })
        },
        {
          heading: 'Closed',
          route: 'main.user.orders.manage',
          key: 'closed',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'CLOSED',
            cursor: null
          })
        },
        {
          heading: 'GRN',
          route: 'main.user.orders.grnManage',
          key: 'grn',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'grn',
            cursor: null
          })
        }
      ]
    } else {
      $scope.tabData = [
        {
          heading: 'All',
          route: 'main.user.orders.manage',
          key: 'all',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'ALL',
            cursor: null
          })
        },
        {
          heading: 'Awaiting delivery',
          route: 'main.user.orders.manage',
          key: 'awaiting_delivery',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'AWAITING_DELIVERY',
            cursor: null
          })
        },
        {
          heading: 'Closed',
          route: 'main.user.orders.manage',
          key: 'closed',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'CLOSED',
            cursor: null
          })
        },
        {
          heading: 'GRN',
          route: 'main.user.orders.grnManage',
          key: 'grn',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'grn',
            cursor: null
          })
        }
      ];

    }

    if ($rootScope.isV2Enabled){
      $scope.tabData.splice.apply($scope.tabData, [1, 0].concat(developerTabs));
    }

    if ($rootScope.isV2Enabled) {
      $scope.tabData.more = [
        {
          heading: 'Expired',
          route: 'main.user.orders.manage',
          key: 'expired',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'EXPIRED',
            cursor: null
          })
        },
        {
          heading: 'Starred',
          route: 'main.user.orders.manage',
          key: 'starred',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'STARRED',
            cursor: null
          })
        },
        {
          heading: 'Cancelled',
          route: 'main.user.orders.manage',
          key: 'cancelled',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'CANCELLED',
            cursor: null
          })
        }
      ];
    } else {
      $scope.tabData.more = [
        {
          heading: 'Submitted',
          route: 'main.user.orders.manage',
          key: 'submitted',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'SUBMITTED',
            cursor: null
          })
        },
        {
          heading: 'Expired',
          route: 'main.user.orders.manage',
          key: 'expired',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'EXPIRED',
            cursor: null
          })
        },
        {
          heading: 'Declined',
          route: 'main.user.orders.manage',
          key: 'declined',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'DECLINED',
            cursor: null
          })
        },
        {
          heading: 'Starred',
          route: 'main.user.orders.manage',
          key: 'starred',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'STARRED',
            cursor: null
          })
        },
        {
          heading: 'Cancelled',
          route: 'main.user.orders.manage',
          key: 'cancelled',
          type: 'more',
          params: globalFunc.filterUrlParams(tempState, {
            status: 'CANCELLED',
            cursor: null
          })
        }
      ];
    }

    if ($rootScope.isV2Enabled) {
      $scope.tabData.splice.apply($scope.tabData.more, [1, 0].concat(developerMoreTabs));
    }
  }
  else {
    $scope.tabData = [
      {
        heading: 'All',
        route: 'main.user.orders.manage',
        key: 'all',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'ALL',
          cursor: null
        })
      },
      {
        heading: 'Awaiting delivery',
        route: 'main.user.orders.manage',
        key: 'awaiting_delivery',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'AWAITING_DELIVERY',
          cursor: null
        })
      },
      {
        heading: 'Submitted',
        route: 'main.user.orders.manage',
        key: 'submitted',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'SUBMITTED',
          cursor: null
        })
      },
      {
        heading: 'Expired',
        route: 'main.user.orders.manage',
        key: 'expired',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'EXPIRED',
          cursor: null
        })
      },
      {
        heading: 'Declined',
        route: 'main.user.orders.manage',
        key: 'declined',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'DECLINED',
          cursor: null
        })
      },
      {
        heading: 'Starred',
        route: 'main.user.orders.manage',
        key: 'starred',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'STARRED',
          cursor: null
        })
      },
      {
        heading: 'Cancelled',
        route: 'main.user.orders.manage',
        key: 'cancelled',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'CANCELLED',
          cursor: null
        })
      },
      {
        heading: 'GRN',
        route: 'main.user.orders.grnManage',
        key: 'grn',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'grn',
          cursor: null
        })
      }
    ];

    if ($rootScope.isV2Enabled){
      $scope.tabData.splice.apply($scope.tabData, [1, 0].concat(developerTabs));
      $scope.tabData.splice.apply($scope.tabData, [6, 0].concat(developerMoreTabs));
    }
  }

  function starred(po) {

    starringPO.update(
      {
        id: po._id,
        action: !!po.isStarred ? 'unstar' : 'star'
      }
    ).
      $promise.then(function (v) {
        //successful
        if (!!po.isStarred) {
          po.isStarred = false;
          po.starred_by = [];
        }
        else {
          po.isStarred = true;
          po.starred_by = [];
          po.starred_by.push($rootScope.currentUser._id);
        }
      },
      function (error) {
        toastr.error(error.message);
      }
    );
  }

  /**
   * Add a PO to selected PO
   * @param po
   */
  function addToSelectedPO(po) {
    if (!poIsSelected(po)) {
      $scope.selectedPO.push({
        _id: po._id,
        po_number: po.po_number,
        title: po.title
      });
    }
  }

  /**
   * Remove a PO from selected PO
   * @param po
   */
  function removeFromSelectedPO(po) {
    _.remove($scope.selectedPO, function (item) {return item._id === po._id});
  }

  /**
   * Check if a PO is selected
   *
   * @param po
   * @returns {boolean}
   */
  function poIsSelected(po) {
    var index = _.findIndex($scope.selectedPO, function(selected) {
      return selected._id === po._id;
    });

    return index > -1;
  }

  /**
   * Remove all PO from selected PO
   */
  function clearSelectedPO() {
    $scope.selectedPO = [];
  }

  /**
   * Prints all selected PO as PDF
   */
  function exportSelectedPoAsPdf() {

    var ids = $scope.selectedPO.map(function (poObject) {
      return poObject._id;
    });

    var idsString = ids.join(',');

    getManyPOPDFHash.get(
      {
        ids: idsString,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function(error){
        if (!!error) {
          globalFunc.objectErrorMessage(error);
        } else {
          toastr.error('Failed to print PO');
        }
      }
    );
  }

  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
      }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if(!!$state.current && !!$state.current.name && $state.current.name.indexOf('grnManage')> -1) {
      setGRNConfig();
    } else{
      setPOConfig();
    }
  }

  initialize();

  $scope.$emit('pageTitleChanged', 'Purchase Orders');

  $scope.$on('$stateChangeStart', function(event, toState, toParams) {
    // Update config if different state i.e. PO -> GRN or GRN -> PO
    if ($state.current.name !== toState.name) {
      if(!!toParams.status) {
        $scope.parentStateParams = toParams;
      }

      if(!!toState.name && toState.name.indexOf('grnManage')> -1) {
        setGRNConfig();
      } else {
        setPOConfig();
      }
    }
  });

  /**
   * page config for GRN option
   */
  function setGRNConfig() {
    $scope.searchPlaceholder = 'Search GRNs';
    $scope.searchState = 'main.user.orders.grnManage';
    $scope.parentState = 'main.user.orders.grnManage';
    $scope.parentStateParams = $state.params;

    $scope.columns = [
      {id: 'grn_number', label: 'GRN #'},
      {id: 'grn_type', label: 'GRN Type', unsearchable: true, unsortable: true},
      {id: 'po_number', label: 'PO #'},
      {id: 'purchase_order.requisition.reference', label: 'PR #', unsortable: true},
      !!$rootScope.isV2Enabled ? {id: 'price-comparisons.pc_number', label: 'PC #'} : $scope.hiddenColumn,
      {id: 'pr_title', label: 'PO TITLE', unsearchable: true, unsortable: true},
      {id: 'billing.company.descr', label: 'COMPANY'},
      {id: 'billing.shadow_company.descr', label: 'BILLING COMPANY'},
      {id: 'supplier_object.descr', label: 'SUPPLIER', unsortable: true},
      {id: 'updated_at', label: 'UPDATED AT', unsearchable: true},
      {id: 'created_at', label: 'CREATED AT', unsearchable: true}
    ];
  }
  /**
   * page config for PO option
   */
  function setPOConfig(){
    $scope.searchPlaceholder = 'Search Orders';
    $scope.searchState = 'main.user.orders.manage';
    $scope.advancedSearchState = 'main.user.advancedSearch.orders';
    $scope.parentState = 'main.user.orders.manage';
    $scope.parentStateParams = $state.params;

    if (!$rootScope.isMobileMode) {
    var receivedPercentageColumn = {id: 'received_percentage', label: 'RECEIVED %', conditional: true, condition: ['APPROVED', 'AWAITING_DELIVERY'], unsearchable: true};
    if (!$rootScope.isV2Enabled) {
      receivedPercentageColumn = {id: 'received_percentage', label: 'RECEIVED %', conditional: true, condition: ['ALL', 'APPROVED', 'AWAITING_DELIVERY'], unsearchable: true};
    }
    $scope.columns = [
      !$scope.isSystemSupportRole ? {id: 'check box', label: '', conditional: false, unsortable: true, unsearchable: true, unSelectable: true} : $scope.hiddenColumn,
      {id: 'starred', label: 'FAVOURITE', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'integration_reference', label: 'ERP PO #',unsearchable: true},
      {id: 'po_number', label: 'PO #'},
      {id: 'pr_number', label: 'PR #'},
      !!$rootScope.isV2Enabled ? {id: 'price_comparisons|pc_number', label: 'PC #'} : $scope.hiddenColumn,
      {id: 'title', label: 'PO TITLE'},
      {id: 'attachment', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'billing|company|descr', label: 'COMPANY'},
      {id: 'billing|shadow_company|descr', label: 'BILLING COMPANY'},
      {id: 'supplier_object|descr', label: 'SUPPLIER'},
      {id: 'needed_at', label: 'NEEDED BY', type: 'date', unsearchable: true},
      {id: 'created_at', label: 'PO DATE', type: 'date', unsearchable: true},
      {id: 'progress', label: 'PROGRESS', conditional: true, condition: ['PENDING'], unsortable: true, unsearchable: true},
      receivedPercentageColumn,
      {id: 'do_number', label: 'DO #', conditional: true, condition: ['CLOSED', 'FULLY_DELIVERED']},
      {id: 'status_date', label: 'CLOSED PO DATE', conditional: true, condition: ['CLOSED', 'FULLY_DELIVERED'], type: 'date', unsearchable: true},
      {id: 'status_date', label: 'CANCELLED PO DATE', conditional: true, condition: 'CANCELLED', type: 'date', unsearchable: true},
      {id: 'net_total', label: 'AMOUNT', type: 'double', unsearchable: true},
      {id: 'updated_at', label: 'UPDATED AT', conditional: true, condition: ['APPROVED', 'AWAITING_DELIVERY']},
      {id: 'status', label: 'STATUS', conditional: true, condition: 'ALL'},
      {id: 'waiting_on', label: 'WAITING ON', conditional: true, condition: ['PENDING'], unsortable: true, unsearchable: true},
      {id: 'creator_info|display_name', label: 'PO CREATOR'},
      !!$rootScope.isV2Enabled ? {id: 'is_printed', label: 'Print Status', conditional: true, condition: ['APPROVED'], unsearchable: true} : $scope.hiddenColumn,
      {id: 'requestor|display_name', label: 'PO REQUESTOR'}
      ];
    }
    else{
      $scope.columns = [
        {id: 'star', label: 'Favorite', unsortable: true, unsearchable: true, unSelectable: true},
        {id: 'reference', label: 'Company/PO#'},
        {id: 'title', label: 'Title'},
        {
          id: 'grand_total',
          label: 'Amount',
          type: 'double',
          unsearchable: true
        },
        {id: 'showMore', label: '', type: 'boolean'}
      ];
    }

  }
}
OrdersCtrl.$inject = ['$scope', '$state', '$rootScope', 'Orders', 'isDeveloper', 'starringPO', 'toastr', 'globalFunc', 'getManyPOPDFHash', '$filter', 'pathConstants'];

angular.module('metabuyer')
  .controller('OrdersCtrl', OrdersCtrl);
