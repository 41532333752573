'use strict';
/**
 * @name MainCtrl
 * @desc The main controller in which the the main panels are loaded NOT including the user information
 */
function MainCtrl($scope, $state, $auth, userSettings, pathConstants, $rootScope, budgetFunctions, globalFunc,
                  $http, UserPermissions, setDashboard, $uibModalStack, bifrostFeatureToggle, plutusLogin,
                  $stateParams, waitingOnUserList) {

  $rootScope.imageBasePath = pathConstants.apiUrls.image.pr;

  /**
   * Setting the date picker input's timezone to be in UTC.
   * To be passed to ng-model options
   * Example: ng-model-options = "datepickerTimezone"
   * @type {{timezone: string}}
   */
  $rootScope.datePickerTimezone = {timezone: 'UTC'};

  $scope.logout = logout;
  $scope.navigateSideBar = navigateSideBar;
  $scope.activateSidePanel = activateSidePanel;
  $scope.toggleSearchBar = toggleSearchBar;
  $scope.devInfo = '';
  $scope.isDevEnvironment = '';
  $scope.activateSidePanelSupplier = activateSidePanelSupplier;
  $scope.changeDashboard = changeDashboard;
  $scope.getSwitcherMenu = getSwitcherMenu;
  $scope.userProfile = $rootScope.currentUser;
  $scope.canChangeDashboard = canChangeDashboard;
  $scope.bifrostFeatureToggle = bifrostFeatureToggle.is_enabled;
  $scope.isDeveloper = isDeveloper;
  $scope.loginPlutus = loginPlutus;
  $scope.navigateToWaitingOnYou = navigateToWaitingOnYou;
  $scope.showSunwayHelpButton = checkSunwayHelpButton();

  function isDeveloper() {
    return UserPermissions.isDeveloper($scope.userProfile);
  }

  function canChangeDashboard() {
    var user = $rootScope.currentUser;

    // Currently only metabuyer user with supplier web role can switch dashboard
    if (!!user.is_metabuyer_user &&
      !!user.is_supplier_web_user &&
      !!UserPermissions.hasSupplierWebUserRole(user)) {
      return true;
    }

    // More condition here //
    /*if ('new condition') {
      return true;
    }*/

    return false;
  }

  function changeDashboard() {
    var currentDashboard = $rootScope.currentUser.config.current_dashboard;

    switch(currentDashboard) {
      case 'metabuyer':
        $rootScope.currentUser.config.current_dashboard = 'supplier';
        break;
      case 'supplier':
        $rootScope.currentUser.config.current_dashboard = 'metabuyer';
        break;
    }

    setDashboard.put(
      {},
      {
        dashboard: $rootScope.currentUser.config.current_dashboard
      }, function () {
        $state.go('main.user.mainMenu.waiting-on-you');
      }
      );
  }

  function getSwitcherMenu(currentDashboard) {
    var menu = '';
    switch(currentDashboard){
      case 'metabuyer':
        menu = 'Supplier Web';
        break;
      case 'supplier':
        menu = 'Metabuyer Web';
        break;
    }
    return menu;
  }

  function activateSidePanelSupplier() {
    return (($state.current.name === 'main.user.suppliersDashboard') ||
      ($state.current.name === 'main.user.suppliersWebManagement.tnc') ||
      ($state.current.name === 'main.user.suppliersWebUsers') ||
      ($state.current.name === 'main.user.suppliersWebUsers.list') ||
      ($state.current.name === 'main.user.suppliersWebUsers.detail') ||
      ($state.current.name === 'main.user.suppliersWebUsers.edit') ||
      ($state.current.name === 'main.user.suppliersWebEmailLog.manage'));
  }

  function getTemplateUrl(isMobileBrowser) {
    switch (!!isMobileBrowser) {
      case true:
          $scope.navbarTemplateUrl = 'components/navbar/navbar-mobile.html';
          $scope.sidebarTemplateUrl = 'components/sidebar/sidebar-mobile.html';
          $scope.mobileClass = 'remove-padding-main';
        break;
      case false :
        $scope.navbarTemplateUrl = 'components/navbar/navbar.html';
        $scope.sidebarTemplateUrl = 'components/sidebar/sidebar.html';
        break;
    }
  }

  function logout() {

    swal({
        title: 'Log out',
        text: 'Are you sure you want to log out?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          userSettings.loggedOut();
          $auth.logout();
          $state.go('login');
        }
      }
    );
  }

  /**
   * The sidebar button
   * @param link
   */
  function navigateSideBar(link) {
    $state.go(link.key, {
      status: link.status,
      tab: link.tab,
      order_by: link.order_by,
      order: link.order,
      extraFilterKey: link.extraFilterKey,
      extraFilterValue: link.extraFilterValue
    });

    if (!!$rootScope.isMobileBrowser) {
      $('body').removeClass('mini-navbar mobile-navbar');
      $('body').addClass('mobile');
    }
  }

  /**
   * Activates the sidebar styling
   * @param option
   * @return {boolean}
   */
  function activateSidePanel (option) {
    return ($state.includes('main.user.purchaseRequisitions') && option.key.indexOf('main.user.purchaseRequisitions') > -1) ||
      ($state.includes('main.user.orders') && option.key.indexOf('main.user.orders') > -1) ||
      ($state.includes('main.user.budget') && option.key.indexOf('main.user.budget') > -1) ||
      ($state.includes('main.user.analytics') && option.key.indexOf('main.user.analytics') > -1) ||
      ($state.includes('main.user.mainMenu.waiting-on-you') && option.key.indexOf('main.user.mainMenu.waiting-on-you') > -1
      && !!$rootScope.isMobileBrowser) ||
      ($state.includes('main.user.mainMenu.notifications') && option.key.indexOf('main.user.mainMenu.notifications') > -1
      && !!$rootScope.isMobileBrowser) ||
        /**
         * work around to avoid duplicating the name check
         * as .includes interprets .suppliersList similar to .suppliers
         */
      ($state.current.name.indexOf('main.user.suppliers.') > -1 && option.key.indexOf('main.user.suppliers.') > -1) ||
      ($state.includes('main.user.suppliersDashboard') && option.key.indexOf('main.user.suppliersDashboard') > -1) ||
      ($state.includes('main.user.suppliersWebManagement') && option.key.indexOf('main.user.suppliersWebManagement') > -1) ||
      ($state.includes('main.user.suppliersWebManagement.tnc') && option.key.indexOf('main.user.suppliersWebManagement.tnc') > -1) ||
      ($state.includes('main.user.suppliersWebUsers') && option.key.indexOf('main.user.suppliersWebUsers') > -1) ||
      ($state.includes('main.user.suppliersWebUsers.list') && option.key.indexOf('main.user.suppliersWebUsers.list') > -1) ||
      ($state.includes('main.user.suppliersWebUsers.detail') && option.key.indexOf('main.user.suppliersWebUsers.detail') > -1) ||
      ($state.includes('main.user.suppliersWebUsers.edit') && option.key.indexOf('main.user.suppliersWebUsers.edit') > -1) ||
      ($state.includes('main.user.suppliersWebEmailLog.manage') && option.key.indexOf('main.user.suppliersWebEmailLog.manage') > -1) ||
      ($state.includes('main.user.suppliersWeb.dashboard') && option.key.indexOf('main.user.suppliersWeb.dashboard') > -1) ||
      ($state.includes('main.user.suppliersWeb.po') && option.key.indexOf('main.user.suppliersWeb.po.') > -1) ||
      ($state.includes('main.user.suppliersList') && option.key.indexOf('main.user.suppliersList') > -1) ||
      ($state.includes('main.user.users') && option.key.indexOf('main.user.users') > -1) ||
      ($state.includes('main.user.items') && option.key.indexOf('main.user.items') > -1) ||
      ($state.includes('main.user.companiesMgmt') && option.key.indexOf('main.user.companiesMgmt') > -1) ||
      ($state.includes('main.user.emailLogs') && option.key.indexOf('main.user.emailLogs') > -1) ||
      ($state.includes('main.user.bifrostFile.manage') && option.key.indexOf('main.user.bifrostFile.manage') > -1) ||
      ($state.includes('main.user.bifrostDataReinbound.manage') && option.key.indexOf('main.user.bifrostDataReinbound.manage') > -1) ||
      ($state.includes('main.user.security') && option.key.indexOf('main.user.security') > -1) ||
      ($state.includes('main.user.reports') && option.key.indexOf('main.user.reports') > -1) ||
      ($state.includes('main.user.exports') && option.key.indexOf('main.user.exports') > -1) ||
      ($state.includes('main.user.settings') && option.key.indexOf('main.user.settings') > -1) ||
      ($state.includes('main.user.approvalFlow') && option.key.indexOf('main.user.approvalFlow') > -1) ||
      ($state.includes('main.user.positionManagement') && option.key.indexOf('main.user.positionManagement') > -1) ||
      ($state.includes('main.user.masterData') && option.key.indexOf('main.user.masterData') > -1) ||
      ($state.includes('main.user.catalogView.catalogItemList') && option.key.indexOf('main.user.catalogView.catalogItemList') > -1) ||
      ($state.includes('main.user.suppliersWeb.reports') && option.key.indexOf('main.user.suppliersWeb.reports') > -1) ||
      ($state.includes('main.user.suppliersWeb.grn.manage') && option.key.indexOf('main.user.suppliersWeb.grn.manage') > -1);
  }

  function loginPlutus() {
    plutusLogin.get(
      {},
      function(response) {
        if (!!response.token) {
          $auth.setToken(response.token);
          window.localStorage.setItem('satellizer_token', response.token);
        }
        if (!!response.redirect_url) {
          var formElem = document.createElement("form");
          formElem.setAttribute("action", response.redirect_url);
          formElem.setAttribute("method", "POST");
          formElem.setAttribute("id", "plutus_redirect");
          var inputElem = document.createElement("input");
          inputElem.setAttribute("value", response.plutus_token);
          inputElem.setAttribute("type", "hidden");
          inputElem.setAttribute("name", "token");
          document.body.appendChild(formElem);
          document.getElementById("plutus_redirect").appendChild(inputElem);
          formElem.submit();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function toggleSearchBar() {
    $scope.displaySearchBar = !$scope.displaySearchBar;
  }

  function navigateToWaitingOnYou() {
    if(!!$state.current.name && $state.current.name.indexOf('waiting-on-you') > -1) {
      waitingOnUserList.get(
        {
          cursor: $stateParams.cursor
        },
        function (resource) {
          if (!!resource.content) {
            $rootScope.waitings = resource.content;
            deferred.resolve(resource.content);
          }
        },
        function (error) {
          if (error.status === 404) {
            deferred.resolve([]);
          }
        }
      );
    } else {
      $state.go('main.user.mainMenu.waiting-on-you')
    }
  }

  function checkSunwayHelpButton() {
    var roleAssignments = $rootScope.currentUser.role_assignments || [];
    var hasRoles = !!roleAssignments[0] || false;
    var isSupplierUser = false;
    if (!!$rootScope.currentUser.user_type && $rootScope.currentUser.user_type === 'SOURCING_SUPPLIER') {
      isSupplierUser = true;
    }
    return !$rootScope.isV2Enabled && (!isSupplierUser || (!!isSupplierUser && !!hasRoles));
  }

  $scope.$on('pageTitleChanged', function (event, data) {
    $scope.pageTitle = data;
    if(!!$rootScope.isMobileBrowser) {
      if ((!!$state.current.name && $state.current.name.indexOf('purchaseRequisitions') > -1) ||
        (!!$state.current.name && $state.current.name.indexOf('orders') > -1)) {
        $scope.enableMobileSearching = true;
      }
      else {
        $scope.enableMobileSearching = false;
      }
    }
  });

  $scope.$on('actionsChanged', function (event, data) {
    $scope.actions = data;
  });

  $scope.$on('$locationChangeStart', function () {
    $uibModalStack.dismissAll();
  });

  function initialize () {
    // Display dev string
    if (!!pathConstants.RollbarConfiguration)  {
      var environment = pathConstants.RollbarConfiguration.environment;
      $scope.isDevEnvironment = environment === 'local' || environment === 'development';

      if (!!$scope.isDevEnvironment && $scope.devInfo === '') {
        $http.get(pathConstants.apiUrls.devInfo)
          .then(function (response) {
            if(typeof response.data === 'string') {
              $scope.devInfo = response.data;
            }
          });
      }
    }

    // Initialize budget setting for company to false
    budgetFunctions.setHasBudgetSettingOn(false);
    // Set true if tenant super admin
    if(!!$rootScope.currentUser &&
      !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) {
      budgetFunctions.setHasBudgetSettingOn(true);
    }
    _.forEach($rootScope.currentUser.companies, function(company) {
      if (!!company.budget_setting && !!company.budget_setting.enable) {
        budgetFunctions.setHasBudgetSettingOn(true);
        return false;
      }
    });

    getTemplateUrl($rootScope.isMobileBrowser);
    //Hide sidebar by default if is mobile browser
    if(!!$rootScope.isMobileBrowser) {
      $(document).ready(function(){
        $('body').removeClass('mini-navbar mobile-navbar');
        $('body').addClass('mobile');
      });

      if ((!!$state.current.name && $state.current.name.indexOf('purchaseRequisitions') > -1) ||
        (!!$state.current.name && $state.current.name.indexOf('orders') > -1)) {
        $scope.enableMobileSearching = true;
      }
      else {
        $scope.enableMobileSearching = false;
      }

      $scope.waitingOnYouLink = {
        key : 'main.user.mainMenu.waiting-on-you'
      };
      $scope.notificationsLink = {
        key : 'main.user.mainMenu.notifications'
      };
      $scope.userProfileLink = {
        key : 'main.user.mainMenu.userProfile'
      };

      $('#page-wrapper').on('click',function () {
        $('body').removeClass('mini-navbar mobile-navbar');
        $('body').addClass('mobile');
      });
    }
  }

  initialize();
}

MainCtrl.$inject = [
  '$scope', '$state', '$auth', 'userSettings', 'pathConstants', '$rootScope', 'budgetFunctions', 'globalFunc',
  '$http', 'UserPermissions', 'setDashboard', '$uibModalStack', 'bifrostFeatureToggle', 'plutusLogin',
  '$stateParams', 'waitingOnUserList',
];

angular.module('metabuyer')
  .controller('MainCtrl', MainCtrl);
